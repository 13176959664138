import React from 'react'

export default function HowToUse() {
    return (
        <div className="main-component-wraper home">
            <div className="home-screen main-component intro">
                <h1 className='title'>Ako používať</h1>
            </div>
        </div>
    )
}
