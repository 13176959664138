import './App.css';
import { BrowserRouter } from 'react-router-dom'
import { Route, Routes } from 'react-router'

import Header from './components/Header'
import HomeScreen from './screens/HomeScreen';
import DocumentationScreen from './screens/DocumentationScreen';
import HowToUse from './screens/HowToUse';
import { useEffect } from 'react';

function App() {

  useEffect(() => {

    window.scrollTo(0, 0)
  }, [])


  return (
    <BrowserRouter>
      <Header></Header>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/dokumentacia" element={<DocumentationScreen />} />
        <Route path="/ako-pouzivat" element={<HowToUse />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
