import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router'
import logo from '../img/go_ride_logo_documentation.svg'
import useWindowDimensions from '../hooks/useWindowDimensions';
import { AnimatePresence, motion } from 'framer-motion'


function Header() {

    const navigate = useNavigate();
    const location = useLocation()

    const { width } = useWindowDimensions()

    const [displayMobileMenu, setDisplaymobileMenu] = useState(false)


    function toggleMenu() {
        setDisplaymobileMenu(!displayMobileMenu)
    }

    const fadeIn = {
        hidden: {
            opacity: 0,
            x: 100
        },

        visible: {
            opacity: 1,
            x: 0,
        },



    }

    return (
        <div className="header-wrap">
            <div className="header">
                <div className="header-logo">
                    <img alt='logo' height="auto" width="auto" onClick={() => { navigate("/") }} src={logo} />
                </div>


                {width >= 900 ? <div className="header-menu">
                    <Link to="/">Domov</Link>
                    <Link to="/dokumentacia">Dokumentácia</Link>
                    <Link to="/ako-pouzivat">Ako používať</Link>
                </div> :

                    <
                        >

                        <div className="menu-trigger" onClick={toggleMenu}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z" fill="#323232" />
                            </svg>

                        </div>
                        <AnimatePresence>
                            {displayMobileMenu &&
                                <motion.div
                                    className='modal'
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.2 }}

                                >
                                    <motion.div
                                        variants={fadeIn}
                                        initial="hidden"
                                        animate="visible"
                                        exit="hidden"
                                        className='modal-wrap'
                                        transition={{ duration: 0.2, ease: 'easeInOut' }}
                                    >

                                        <div className="close-icon" onClick={toggleMenu}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.3002 5.70997C17.9102 5.31997 17.2802 5.31997 16.8902 5.70997L12.0002 10.59L7.11022 5.69997C6.72022 5.30997 6.09021 5.30997 5.70021 5.69997C5.31021 6.08997 5.31021 6.71997 5.70021 7.10997L10.5902 12L5.70021 16.89C5.31021 17.28 5.31021 17.91 5.70021 18.3C6.09021 18.69 6.72022 18.69 7.11022 18.3L12.0002 13.41L16.8902 18.3C17.2802 18.69 17.9102 18.69 18.3002 18.3C18.6902 17.91 18.6902 17.28 18.3002 16.89L13.4102 12L18.3002 7.10997C18.6802 6.72997 18.6802 6.08997 18.3002 5.70997Z" fill="red" />
                                            </svg>

                                        </div>

                                        <div className="mobile-menu-wrap">
                                            <div className="mobile-menu">
                                                <div className="menu-items">
                                                    <h1>Menu</h1>
                                                    <Link onClick={toggleMenu} to="/">Domov</Link>
                                                    <Link onClick={toggleMenu} to="/dokumentacia">Dokumentácia</Link>
                                                    <Link onClick={toggleMenu} to="/ako-pouzivat">Ako používať</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </motion.div>
                                </motion.div>}

                        </AnimatePresence>
                    </>

                }

            </div>
        </div>
    )
}


export default Header