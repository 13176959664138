import React, { useState } from 'react'

export default function TableOfContent({ headings }) {


    const [extended, setextended] = useState([])

    function handleExtand(i) {

        console.log(i)

        var arr = [...extended]

        if (arr.includes(i)) {
            const index = arr.indexOf(i);

            if (index > -1) {
                arr.splice(index, 1); // 2nd parameter means remove one item only
            }
        } else {
            arr.push(i)
        }

        setextended(arr)

    }



    return (
        <div className="table-of-content">
            <h2>Obsah</h2>
            <ul className='main-cat'>
                {headings.map((item, i) => {
                    return (
                        <>
                            <li key={i} className={`${item.more ? "extentable" : ""} ${extended.includes(i) ? "extended" : ""}`}>
                                {item.more &&
                                    <div onClick={() => { handleExtand(i) }} >
                                        <svg width="5" height="10" viewBox="0 0 5 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 10L5 5L0 0V10Z" fill="#323232" />
                                        </svg>
                                    </div>
                                }

                                <a href={`#${item.link}`}>{i + 1} {item.name}</a>
                            </li>

                            {item.more && extended.includes(i) &&
                                <>
                                    <ul className='sub-cat'>
                                        {
                                            item.more.map((subItem, j) => {

                                                return (

                                                    <li key={`${j}${i}`}><a href={`#${item.link}`}>{i + 1}.{j + 1} {item.name}</a></li>

                                                )

                                            })
                                        }
                                    </ul>
                                </>

                            }
                        </>


                    )

                })}
            </ul>
        </div>


    )
}

