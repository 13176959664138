import React from 'react'
import TableOfContent from '../components/TableOfContent'

export default function DocumentationScreen() {

    const headings =
        [
            {
                name: "Úvod",
                link: "uvod",
            },
            {
                name: "Ciele práce",
                link: "ciele-prace",
            },
            {
                name: "Teoretické východiská",
                link: "teoreticke-vychodiska",
            },
            {
                name: "Teoretické východiská",
                link: "teoreticke-vychodiska",

                more: [
                    {
                        name: "Uvod",
                        link: "uvod",

                    },
                    {
                        name: "Uvod",
                        link: "uvod",

                    },
                    {
                        name: "Uvod",
                        link: "uvod",

                    },
                ]
            },
            {
                name: "Uvod",
                link: "uvod",
            },
            {
                name: "Uvod",
                link: "uvod",
            },
        ]

    return (
        <div className="main-component-wraper home">
            <div className="documentation main-component intro">

                <TableOfContent headings={headings}></TableOfContent>

                <div className="content-wrap">
                    <div className="content">

                        <h1 className='title'>Dokumentácia projektu Go-Ride</h1>

                        <section id='uvod'>
                            <h1>Úvod</h1>
                            <p>
                                Cez leto v roku 2020 som sa zúčastnil čiastočne profesionálneho závodu motorkárov na okruhu Slovakia Ring. Jednalo sa o 2 preteky počas dvoch mesiacov, ktorých sa zúčastnilo približne 90 pretekárov zo Slovenska, Česka, Rakúska a Polska. Počas tejto spolupráce som mal na starosti registráciu a evidenciu jazdcov.
                            </p>
                            <p>
                                Na prvom preteku, kedy som ešte úplne nevedel do čoho idem, som dostal doslova studenú sprchu, keď 20 nahnevaných jazdcov čakalo na registráciu, ktorá síce netrvala až tak dlho, ale ustrážiť si všetky dáta a nestratiť pri tom hlavu bolo v podstate nemožné. Na druhom preteku som sa poučil, a pripravil som si veľmi prepracované tabuľky, ktoré mali registráciu sprehľadniť. Výsledok síce bol o niečo lepší, no stále sa registrácia nezaobišla bez zbytočného čakania a chaosu.
                            </p>
                            <p>
                                Po krátkom rozmýšľaní som identifikoval problém. Nenašiel som prehľadný, rýchly a na moje potreby zameraný softvér, ktorý by mi sprehladnil prácu, zefektívnil proces registrácie a bol priamo vytvorený na konkrétne situácie, ktoré počas preteku môžu nastať. Toto riešenie by neušetrilo čas iba mne a organizátorom preteku, ale aj jazdcom, ktorí si prišli na trať po poriadnu dávku adrenalínu, ku ktorej  čakanie v rade pri registrácií určite nepatrí.
                            </p>
                            <p>
                                Vedený motom záhradkárov a kutilov, “urob si sám” som sa rozhodol, že so svojimi skúsenosťami a znalosťami, nielen z oblasti IT, vytvorím webovú aplikáciu, ktorá vyrieši všetky už zmienené problémy. Výsledkom mojej snahy je webová platforma Go ride, ktorú som sa rozhodol v tejto súťaži prezentovať.
                            </p>
                        </section>

                        <section id='ciele-prace'>
                            <h1>Ciele práce</h1>
                            <p>
                                Cieľom tejto práce je zužitkovať získané skúsenosti z pretekov v kombinácii so znalosťami, ktoré som získal štúdiom na Strednej Odbornej škole informačných technológií. Svoju prácu som rozdelil do niekoľkých celkov, z ktorých každý rieši časť problému a dohromady tvoria jeden integrovaný celok.

                            </p>
                            <p>
                                Webová aplikácia pre organizátorov, v skratke “admin”, je vytvorená pre organizátorov pretekov. Admin umožňuje organizátorovi vytvoriť svoj vlastný pretek a zadať jeho názov, dátum a miesto konania, cenu za registráciu, krátky popis a fotografiu udalosti, časový harmonogram podujatia, ako aj úroveň obtiažnosti.
                            </p>
                            <p>
                                Webová aplikácia pre účastníkov slúži na zobrazenie pretekov, ktoré vytvorili organizátori. Všetky vyššie uvedené dáta o preteku zobrazí v prehľadom formáte tak, aby bola zachovaná  konzistentnosť a prehľadnosť informácií. Používateľ sa následne môže na pretek zaregistrovať priamo cez stránku, zadať všetky potrebné informácie a zaplatiť uvedený registračný poplatok prostredníctvom platobnej karty či hotovosti. Po uhradení poplatku sa registrácia  používateľovi odošle na zvolený email aj s QR kódom, ktorý slúži na identifikáciu danovej registrácie.
                            </p>
                            <p>
                                Mobilná aplikácia Go Ride Scan, v skratke G-Scan, umožňuje skenovať vygenerované registrácie, overovať ich platnosť a evidovať prítomnosť jazdcov. Naskenované registrácie sa následne odošlú do adminu, kde sa v takzvanom “Live View” zobrazia iba tie registrácie, ktoré boli práve naskenované a ešte nie sú vybavené (jazdcovi nebola pridelená kategória a štartovné číslo). Dáta v tejto sekcií sa dajú upravovať v reálnom čase a slúžia na čo najrýchlejšie vybavenie registrácie.
                            </p>

                        </section>

                        <section id='teoreticke-vychodiska'>
                            <h1>Teoretické východiská</h1>

                            <p>Ako prvé som vykonal charakteristiku problému a presne zacielil koncových používateľov, ktorými sú polo-profesionálne, ale aj neprofesionálne motocyklové agentúry, aktívne organizácie vytvárajúce pravidelné preteky pre 50 - 200 jazdcov, organizácie ktoré chcú realizovať registráciu a propagácie svojich pretekov elektronicky. Na základe tejto špecifikácie som vykonal prieskum trhu a konkurencie.
                            </p>

                            <p>
                                Aplikácie pre organizátorov pretekov už samozrejme existujú. Avšak žiadna z tých, ktoré som našiel neposkytovala priamo integrovanú registráciu na konkrétny pretek. Väčšinou sa jednalo o webové stránky, ktoré zoskupovali informácie o danom preteku, podobne ako môj admin, no slúžili skôr na vytvorenie prehľadu, nie na priamu interakciu s účastníkmi.
                            </p>

                            <p>
                                Taktiež som si musel vybrať, či bude daná aplikácia vytvorená ako web alebo ako mobilná aplikácia. Prvá možnosť sa mi zdala ako najatraktívnejšia, hlavne kvôli dostupnosti a flexibilite. Navyše, admin potreboval byť na väčšej obrazovke aby bola registrácia naozaj prehľadná a rýchla. Z tohto dôvodu som sa rozhodol pre vytvorenie aplikácie na webe.

                            </p>

                            <p>Na frontende som sa rozhodol použiť webový framework React.js. Je to framework založený na práci so znovapoužitelnými komponentami, ktoré vykresule do virtuálneho DOM modelu, čo v značnej miere urýchluje načítavanie a interakciu používateľa s obsahom. Najviac ma ale upútala open-source komunita a prehľadná dokumentácia, ktorá veľmi urýchlila proces tvorby.
                            </p>

                            <p>Na autentifikáciu, databázu, backend a hosting som sa rozhodol použiť Firebase. Jedná sa o cloudovú riešenie, ktoré ponúka služby zamerané práve na tvorbu prototypov alebo začínajúcich aplikácií. Opäť ma presvedčila prehľadná dokumentácia, jednoduchá implementácia, ako aj funkcionality ktoré služba ponúka. Jednou z kľúčových funkcionalít je práve práca s dátami v reálnom čase, ktorú bližšie popisujem v kapitole 4.8 Live registrácie.
                            </p>



                        </section>


                        <section>
                            <h1>Sekcia 1</h1>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Repudiandae unde iusto, rem reprehenderit aspernatur facilis optio eius nulla eligendi? Perspiciatis cumque est consequuntur eos magni labore itaque enim nisi atque.</p>
                        </section>
                        <section>
                            <h1>Sekcia 1</h1>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Repudiandae unde iusto, rem reprehenderit aspernatur facilis optio eius nulla eligendi? Perspiciatis cumque est consequuntur eos magni labore itaque enim nisi atque.</p>
                        </section>
                        <section>
                            <h1>Sekcia 1</h1>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Repudiandae unde iusto, rem reprehenderit aspernatur facilis optio eius nulla eligendi? Perspiciatis cumque est consequuntur eos magni labore itaque enim nisi atque.</p>
                        </section>
                        <section>
                            <h1>Sekcia 1</h1>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Repudiandae unde iusto, rem reprehenderit aspernatur facilis optio eius nulla eligendi? Perspiciatis cumque est consequuntur eos magni labore itaque enim nisi atque.</p>
                        </section>
                        <section>
                            <h1>Sekcia 1</h1>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Repudiandae unde iusto, rem reprehenderit aspernatur facilis optio eius nulla eligendi? Perspiciatis cumque est consequuntur eos magni labore itaque enim nisi atque.</p>
                        </section>
                        <section>
                            <h1>Sekcia 1</h1>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Repudiandae unde iusto, rem reprehenderit aspernatur facilis optio eius nulla eligendi? Perspiciatis cumque est consequuntur eos magni labore itaque enim nisi atque.</p>
                        </section>
                        <section>
                            <h1>Sekcia 1</h1>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Repudiandae unde iusto, rem reprehenderit aspernatur facilis optio eius nulla eligendi? Perspiciatis cumque est consequuntur eos magni labore itaque enim nisi atque.</p>
                        </section>




                    </div>
                </div>

            </div>
        </div>
    )
}
