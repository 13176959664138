import React from 'react'

export default function HomeScreen() {
    return (
        <div className="main-component-wraper home">
            <div className="home-screen main-component intro">
                <h1 className='title'>Dokumentácia projektu Go-Ride</h1>
            </div>
        </div>
    )
}
